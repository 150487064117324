export const changePasswordValidation = (
  password,
  passwordConfirmation,
  setValidated
) => {
  password && password.length < 8
    ? setValidated((prevState) => ({
        ...prevState,
        password: true,
      }))
    : setValidated((prevState) => ({
        ...prevState,
        password: false,
      }));
  passwordConfirmation && passwordConfirmation.length < 8
    ? setValidated((prevState) => ({
        ...prevState,
        passwordConfirmation: true,
      }))
    : setValidated((prevState) => ({
        ...prevState,
        passwordConfirmation: false,
      }));
  password !== passwordConfirmation
    ? setValidated((prevState) => ({
        ...prevState,
        passwords: true,
      }))
    : setValidated((prevState) => ({
        ...prevState,
        passwords: false,
      }));
  return (password && password.length < 8) ||
    (passwordConfirmation && passwordConfirmation.length < 8) ||
    password !== passwordConfirmation
    ? false
    : true;
};
