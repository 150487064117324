const handleNextQuestion = (currentQuestion, setCurrentQuestion, questionFilters, setFinishQuestions, setPreview) => {
  const nextButton = currentQuestion + 1;
  if (nextButton < questionFilters.length) {
    setCurrentQuestion(nextButton);
  } else {
    setFinishQuestions(true);
    setPreview(true);
  }
};

const handlePreviousQuestion = (currentQuestion, setCurrentQuestion, setPreview, setFinishQuestions, setShowQuestion) => {
  const previousButton = currentQuestion - 1;
  if (previousButton >= 0) {
    setCurrentQuestion(previousButton);
    setPreview(false);
    setFinishQuestions(false);
  } else {
    setShowQuestion(false);
  }
};

export { handleNextQuestion, handlePreviousQuestion };
