import React, { useContext, useEffect, useState } from 'react';
// import DatePicker, { registerLocale } from "react-datepicker";
import ReactInputDateMask from 'react-input-date-mask';
// import InputMask from "react-input-mask";
// import pt from "date-fns/locale/pt";
import Select from 'react-select';

import FormaDocContext from '../context/FormaDocContext';
import { fetchCities, fetchClients, fetchProcessInfoByCpj, fetchTemplates, fetchTemplatesDetails } from '../utils/api';

import '../styles/inputsProcedural.css';

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: 'rgb(248, 244, 244)',
    border: 'none',
    borderRadius: 5,
    boxShadow: state.isFocused ? '0px 0px 9px 3px rgba(41, 41, 41, 0.25)' : null,
    height: 44,
    marginBottom: 15,
    marginRight: 70,
    width: '400px',
    '@media(max-width: 1060px)': {
      ...base['@media(max-width: 1060px)'],
      width: '100%',
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: -10,
    width: '400px',
    '@media(max-width: 1060px)': {
      ...base['@media(max-width: 1060px)'],
      width: '100%',
    },
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    background: 'rgb(248, 244, 244)',
  }),
};

function InputsProcedural({
  name,
  setName,
  customer,
  setCustomer,
  date,
  setDate,
  city,
  setCity,
  legalProcessNumber,
  setLegalProcessNumber,
  incidentNumber,
  setIncidentNumber,
  setProcessByCpj,
  template,
  setTemplate,
  setIntroduction,
  setConclusion,
  documentFormat,
  setDocumentFormat,
}) {
  const [clients, setClients] = useState([]);
  const [cities, setCities] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [questions, setQuestions] = useState();
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [searchTemplate, setSearchTemplate] = useState('');
  const [showIncidentNumber, setShowIncidentNumber] = useState(false);
  const { setAnswerQuestions, setCompleteAnswers, handleQuestionFilters, user, handleCompleteAnswer } =
    useContext(FormaDocContext);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    fetchCities(accessToken).then((res) => {
      const cities = res.data.map((city) => ({ label: city.attributes.name, value: city.id }));
      setCities(cities);
    });

    fetchClients(accessToken).then((res) => {
      setClients(res.data.map((client) => ({ label: client.attributes.name, value: client.id })));
    });
  }, []);

  useEffect(() => {
    if (user.hasOwnProperty('office')) {
      setShowIncidentNumber(
        user.office.attributes.hasOwnProperty('process_subidentifier') && user.office.attributes.process_subidentifier,
      );
    }
  }, [user]);

  useEffect(() => {
    const access_token = JSON.parse(localStorage.getItem('access_token'));
    if (customer.value) {
      setLoadingTemplate(true);
      fetchTemplates(access_token, customer.value, searchTemplate).then((res) => {
        setTemplates(res.data.map((template) => ({ label: template.attributes.name, value: template.id })));
        setLoadingTemplate(false);
      });
    }
  }, [customer, searchTemplate]);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    if (legalProcessNumber) {
      fetchProcessInfoByCpj(accessToken, legalProcessNumber, incidentNumber, showIncidentNumber).then((res) => {
        if (res.data.length === 0) {
          setProcessByCpj(null);
        } else {
          setProcessByCpj(res.data);
        }
      });
    }
  }, [showIncidentNumber, legalProcessNumber, incidentNumber, setProcessByCpj]);

  useEffect(() => {
    if (questions) {
      const { included } = questions;
      const options = included.filter((item) => item.type === 'option');

      const topics = included.filter((item) => item.type === 'topic');

      const question = included.filter((item) => item.type === 'question');
      question.sort((questionA, questionB) => questionA.attributes.order - questionB.attributes.order);

      const findOption = (optionId) => {
        return options.find((option) => option.id === optionId);
      };
      const questionsAndOptions = question.map(({ attributes, relationships }) => {
        const { title, order, question_type } = attributes;
        const {
          options: { data: options },
        } = relationships;
        const optionsData = options.map(({ id }) => id).map(findOption);
        const optionsTopic = optionsData.map((option) => {
          const { id } = option.relationships.topic.data || {};
          if (id) {
            option.relationships.topic.data = topics.find((topic) => topic.id === id);
          }
          return option;
        });
        return {
          title,
          order,
          questionType: question_type,
          answers: optionsTopic,
        };
      });

      const questionWithoutOptions = questionsAndOptions.filter((question) => question.questionType === 'topic');
      if (questionWithoutOptions.length) {
        questionWithoutOptions.forEach((question) => handleCompleteAnswer(question.order, ...question.answers));
      }

      handleQuestionFilters(questionsAndOptions.filter((question) => question.questionType !== 'topic'));
    }
  }, [questions, handleQuestionFilters, handleCompleteAnswer]);

  const documentFormats = [
    { value: 'odt', label: 'ODT' },
    { value: 'pdf', label: 'PDF' },
  ];

  useEffect(() => {
    const access_token = JSON.parse(localStorage.getItem('access_token'));
    if (template) {
      fetchTemplatesDetails(access_token, template.value).then((res) => {
        setQuestions(res);
        setIntroduction({
          title: res.data.attributes.introduction_title,
          content: res.data.attributes.introduction,
        });
        setConclusion({
          title: res.data.attributes.conclusion_title,
          content: res.data.attributes.conclusion,
        });
      });
      setAnswerQuestions({});
      setCompleteAnswers({});
    }
  }, [template, setAnswerQuestions, setCompleteAnswers]); // eslint-disable-line

  const noOptionsMessage = () => {
    return loadingTemplate ? 'Carregando...' : 'Nada encontrado';
  };

  const handleCustomer = (value) => {
    setCustomer(value);
    setTemplate('');
  };

  return (
    <div className="input-container">
      <div className="div-input">
        <label htmlFor="name">Nome da peça</label>
        <input value={name} className="input-new" onChange={(event) => setName(event.target.value)} type="text" id="name" />
      </div>
      <div className="div-input">
        <label htmlFor="customer">Cliente</label>
        <Select
          name="customer"
          styles={customStyles}
          options={clients}
          value={customer}
          // onInputChange={(data) => {
          //   setSearchTemplate(data);
          // }}
          onChange={(data) => {
            handleCustomer(data);
          }}
          noOptionsMessage={() => 'Cliente não foi encontrado'}
        />
      </div>
      <div className="div-input">
        <p className="p-date">Data</p>
        <ReactInputDateMask
          mask="dd/mm/yyyy"
          className="input-new"
          value={date}
          onChange={(value) => setDate(value)}
          showMaskOnFocus={true}
          showMaskOnHover
          placeholder="dd/mm/aaaa"
        />
      </div>
      <div className="div-input">
        <label htmlFor="city">Comarca de assinatura do documento</label>
        <Select
          styles={customStyles}
          options={cities}
          value={cities.find((item) => item.value === city.value)}
          // onInputChange={(data) => {
          //   setCity(data);
          // }}
          onChange={(data) => {
            setCity(data);
          }}
          // className="input-new select-input"
          noOptionsMessage={() => 'Cidade não foi encontrada'}
        />
      </div>
      <div className="div-input">
        <label htmlFor="legal-process">Processo Jurídico</label>
        <input
          value={legalProcessNumber}
          className="input-new"
          onChange={(event) => setLegalProcessNumber(event.target.value)}
          type="text"
          id="legal-process"
        />
      </div>
      {showIncidentNumber && (
        <div className="div-input">
          <label htmlFor="incident-number">{user.office.attributes.process_subidentifier}</label>
          <input
            value={incidentNumber}
            className="input-new"
            onChange={(event) => setIncidentNumber(event.target.value)}
            type="text"
            id="incident-number"
          />
        </div>
      )}
      <div className="div-input">
        <label htmlFor="template">Template</label>
        <Select
          id="template"
          styles={customStyles}
          noOptionsMessage={() => noOptionsMessage()}
          value={template}
          options={templates}
          onInputChange={(data) => {
            setSearchTemplate(data);
          }}
          onChange={(data) => setTemplate(data)}
        />
      </div>
      <div className="div-input">
        <label htmlFor="document-format">Gerar em</label>
        <Select
          styles={customStyles}
          options={documentFormats}
          value={documentFormat}
          onChange={(data) => {
            setDocumentFormat(data);
          }}
          noOptionsMessage={() => 'Formato não foi encontrado'}
        />
      </div>
    </div>
  );
}

export default InputsProcedural;
