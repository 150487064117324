import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import FormaDocContext from '../context/FormaDocContext';

function PreviewModal({ show, onHide, process, page }) {
  const history = useHistory();
  const { user } = useContext(FormaDocContext);

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          {process.errors || process.error || process.status === 'unprocessable_entity' ? (
            page === 'create' ? (
              <Modal.Title>Não foi possível fazer o cadastro</Modal.Title>
            ) : page === 'changePassword' ? (
              <Modal.Title>Não foi possível alterar a senha</Modal.Title>
            ) : (
              <Modal.Title>Não foi possível fazer a edição</Modal.Title>
            )
          ) : (
            <Modal.Title>Parabéns, {user.name}!</Modal.Title>
          )}
        </Modal.Header>
        {process.errors || process.error ? (
          process.errors ? (
            process.messages.map((e, index) => <Modal.Body key={index}>{e}</Modal.Body>)
          ) : (
            <Modal.Body>{process.error}</Modal.Body>
          )
        ) : page === 'create' ? (
          <Modal.Body>Você cadastrou sua peça com sucesso.</Modal.Body>
        ) : page === 'changePassword' ? (
          <Modal.Body>Você alterou sua senha com sucesso.</Modal.Body>
        ) : (
          <Modal.Body>Você editou sua peça com sucesso.</Modal.Body>
        )}
        <Modal.Footer>
          {!process.errors && !process.error ? (
            <Button variant="primary" onClick={() => history.push('/minhas-pecas')}>
              Minhas peças
            </Button>
          ) : (
            <Button variant="secondary" onClick={onHide}>
              Fechar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PreviewModal;
