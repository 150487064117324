import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import '../styles/headerSideBar.css';
// import { FaBars } from 'react-icons/fa';
// import { SideBarData } from './SideBarData';
import FormaDocContext from '../context/FormaDocContext';
import Menu from './Menu';

function HeaderSideBar() {
  const { user, handleUser } = useContext(FormaDocContext);
  // const [sidebar, setSidebar] = useState(false);

  // const showSideBar = () => setSidebar(!sidebar);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    handleUser(user);
  }, [handleUser]);

  return (
    <div>
      <div className="header">
        {/* <div> */}
        {/* <Link to='#' className="menu-bars">
              <FaBars onClick={showSideBar} color="black" size="25" />
            </Link> */}
        {user.signInCount <= 1 ? (
          <img src={logo} height="40" alt="logo" className="logo" />
        ) : (
          <Link to="/minhas-pecas">
            <img src={logo} height="40" alt="logo" className="logo" />
          </Link>
        )}
        {/* </div> */}
        {/* <div className="header-end"> */}
        <p className="welcome">Bem vindo(a) {user.name}</p>
        <Menu firstAccess={user.signInCount <= 1} />
        {/* <button className="button-logout" onClick={logout}>
          <FiLogOut />
        </button> */}
        {/* </div> */}
      </div>
      {/* <div className="div-side-bar">
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars" onClick={showSideBar}>
                <AiOutlineClose color="#edb72d" />
              </Link>
            </li>
            {SideBarData.map((item, index) => (
              <li key={index} className="nav-text">
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div> */}
    </div>
  );
}

export default HeaderSideBar;
