const convertDateForApi = (date) => {
  const day = date.split('/')[0];
  const month = date.split('/')[1];
  const year = date.split('/')[2];
  return `${year}-${month}-${day}`;
};

const convertDateForFront = (date) => {
  const day = date.split('-')[2];
  const month = date.split('-')[1];
  const year = date.split('-')[0];
  return `${day}/${month}/${year}`;
};

const extensiveDate = (dat) => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  let month = dat.split('/')[1];
  if (month <= 9) month = month.replace(/^[0.]+/, '');

  const day = dat.split('/')[0];
  month = months[month - 1];
  const year = dat.split('/')[2];

  return `${day} de ${month} de ${year}.`;
};

const formattedDate = (newDate) => {
  const day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  if (month <= 9) month = `0${month}`;

  return `${day}/${month}/${year}`;
};

const verifyDate = (date) => {
  const array = date.split('');
  const caracteres = ['d', 'm', 'y'];
  return array.some((ar) => caracteres.includes(ar));
};

export { convertDateForApi, convertDateForFront, extensiveDate, formattedDate, verifyDate };
