import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import { Pagination } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaginationControlled({ totalPages, currentPage, setCurrentPage }) {
  const classes = useStyles();

  const handleChange = (_event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className={classes.root}>
      {/* <Typography>Page: {currentPage}</Typography> */}
      <Pagination count={totalPages} page={currentPage} onChange={handleChange} variant="outlined" shape="rounded" boundaryCount={2}/>
    </div>
  );
}