import React, { useState } from 'react';
import logo from '../images/logo.png';
import '../styles/forgetPassword.css';
import { fetchResetPassword } from '../utils/api';
import { useHistory } from 'react-router';

function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [messageSuccess, setMessageSuccess] = useState(false);

  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    fetchResetPassword(email);
    setMessageSuccess(true);
    setTimeout(() => setMessageSuccess(false), 3000);
  }

  const backToLogin = () => {
    history.push('/');
  }

  return (
    <div className="page-login">
      {messageSuccess && <div className="instructions-sent">
        <p>Se seu endereço de email existir em nosso banco de dados, você receberá um link de redefinição de senha em alguns minutos</p>
      </div>}
      <div className="login">
        <img src={logo} height="50" alt="logo" className="logo-login" />
        <p className="paragraph-forgot">Esqueci minha senha</p>
        <p className="paragraph-inform">Informe seu email cadastrado</p>
        <form onSubmit={handleClick}>
          <div className="div-input-login">
            <label htmlFor="email" className="label-login">
              E-mail
            </label>
            <input
              required
              className="input-login"
              type="email"
              id="email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <button
            className="btn-forget"
            type="submit"
          >
            Enviar instruções para redefinição de senha
        </button>
        </form>
        <button className="forgot-password" onClick={backToLogin}>Login</button>
      </div>
    </div>
  );
}

export default ForgetPassword;
