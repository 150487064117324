import React, { useState } from 'react'; // eslint-disable-line
import { VscKebabVertical } from 'react-icons/vsc';
import { convertToRoman, convertVariables as convertVariablesHelper, extensiveDate, replaceTabsForSections } from '../helper';
import footerBarcelos from '../images/footer_barcelos.png';
import logoBarcelos from '../images/logo_barcelos.png';
import EditorText from './EditorText';

import '../styles/previewProcedural.css';

function PreviewProcedural({
  name,
  processByCpj,
  date,
  city,
  legalProcessNumber,
  incidentNumber,
  introduction,
  setIntroduction,
  conclusion,
  setConclusion,
  topic,
  setTopic,
  variables,
}) {
  const editTopics = () => {
    const keys = {};
    for (let i = 0; i < topic.length; i++) {
      keys[i] = false;
    }
    return keys;
  };

  const [editIntroductionTitle, setEditIntroductionTitle] = useState(false);
  const [editIntroductionContent, setEditIntroductionContent] = useState(false);
  const [editTopicTitle, setEditTopicTitle] = useState(editTopics());
  const [editTopicContent, setEditTopicContent] = useState(editTopics());
  const [editConclusionTitle, setEditConclusionTitle] = useState(false);
  const [editConclusionContent, setEditConclusionContent] = useState(false);

  const convertVariables = (convert, processByCpj, variables, message) => {
    convert = convertVariablesHelper(convert, processByCpj, variables, message);
    return replaceTabsForSections(convert);
  };

  const convertTitle = (convert, message) => {
    if (convert) {
      convert = convert.replace(/<[^>]*>/gi, '');
    }

    if (message) {
      convert = convertVariables(convert, processByCpj, variables, message);
    }

    return convert;
  };

  const handleEditTopic = (index, key) => {
    if (key === 'title') {
      setEditTopicTitle((editTopicTitle) => ({
        ...editTopicTitle,
        [index]: !editTopicTitle[index],
      }));
    } else {
      setEditTopicContent((editTopicContent) => ({
        ...editTopicContent,
        [index]: !editTopicContent[index],
      }));
    }
  };

  const handleIntroduction = (content, key) => {
    setIntroduction((introduction) => ({
      ...introduction,
      [key]: content,
    }));
  };

  const handleTopic = (content, index, key) => {
    const newTopic = [...topic];
    newTopic[index].relationships.topic.data.attributes[key] = content;
    setTopic(newTopic);
  };

  const handleConclusion = (content, key) => {
    setConclusion((introduction) => ({
      ...introduction,
      [key]: content,
    }));
  };

  return (
    <div className="a4-page">
      {!introduction && !topic && !conclusion ? (
        <p className="dont-template">
          Este template não possui tópicos, introdução nem conclusão. Por favor contacte os responsáveis para que finalizem a
          criação deste template.
        </p>
      ) : (
        <>
          <div className="img">
            <img src={logoBarcelos} alt="logo Barcelos" className="logo-barcelos" />
          </div>
          <br />
          <br />
          {/* <p className="second-block">
            <strong>
              {name}
            </strong>
          </p>
          <br />
          <p className="second-block">
            <strong>PROCESSO Nº {legalProcessNumber}</strong>
          </p>
          <p className="second-block">
            <strong>AUTOR: {clientName && clientName.toUpperCase()}</strong>
          </p>
          <p className="second-block">
            <strong>RÉU: { oppositeParty && oppositeParty.toUpperCase()}</strong>
          </p>
          <br /> */}
          <div className="introduction">
            <div className="introduction-title-container">
              <div className="editor-text">
                <VscKebabVertical title="Editar" onClick={() => setEditIntroductionTitle(true)} />
              </div>
              {editIntroductionTitle && (
                <>
                  <EditorText defaultValue={introduction.title} onChange={(content) => handleIntroduction(content, 'title')} />
                  <div className="btn-save-container">
                    <button className="btn-save" onClick={() => setEditIntroductionTitle(false)}>
                      Salvar
                    </button>
                  </div>
                </>
              )}
              {!editIntroductionContent && <div className="first-header">{convertTitle(introduction.title, 'title')}</div>}
            </div>
            <div className="introduction-content-container">
              <div className="editor-text">
                <VscKebabVertical title="Editar" onClick={() => setEditIntroductionContent(true)} />
              </div>
              {editIntroductionContent && (
                <>
                  <EditorText
                    defaultValue={introduction.content}
                    onChange={(content) => handleIntroduction(content, 'content')}
                  />
                  <div className="btn-save-container">
                    <button className="btn-save" onClick={() => setEditIntroductionContent(false)}>
                      Salvar
                    </button>
                  </div>
                </>
              )}
              {!editIntroductionContent && (
                <>
                  <div
                    className="introduction-content"
                    dangerouslySetInnerHTML={{
                      __html: convertVariables(introduction.content, processByCpj, variables),
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
          <br />
          <div className="container-content">
            {/* <div className="general-paragraph"> */}
            {/* <strong>EMPRESA TAL</strong> devidamente qualificada nos
              autos acima referenciados, em atenção aos termos da r.
            citação de fls., vem, por seus procuradores, apresentar{" "} */}
            {topic.map(({ id, relationships }, index) => {
              let { title } = relationships.topic.data.attributes;
              title = convertVariables(title, processByCpj, variables, 'title');
              let { content } = relationships.topic.data.attributes;
              content = convertVariables(content, processByCpj, variables);
              return (
                <div key={id + 1} className="general-paragraph">
                  <div className="topic-title">
                    <div className="editor-text">
                      <VscKebabVertical title="Editar" onClick={() => handleEditTopic(index, 'title')} />
                    </div>
                    {editTopicTitle[index] && (
                      <>
                        <EditorText defaultValue={title} onChange={(content) => handleTopic(content, index, 'title')} />
                        <div className="btn-save-container">
                          <button className="btn-save" onClick={() => handleEditTopic(index, 'title')}>
                            Salvar
                          </button>
                        </div>
                      </>
                    )}
                    {!editTopicTitle[index] && (
                      <p>
                        <strong>
                          {`${convertToRoman(index + 1)}. `}
                          {convertTitle(title)}
                        </strong>
                      </p>
                    )}
                  </div>
                  <div className="topic-content">
                    <div className="editor-text">
                      <VscKebabVertical title="Editar" onClick={() => handleEditTopic(index, 'content')} />
                    </div>
                    {editTopicContent[index] && (
                      <>
                        <EditorText defaultValue={content} onChange={(content) => handleTopic(content, index, 'content')} />
                        <div className="btn-save-container">
                          <button className="btn-save" onClick={() => handleEditTopic(index, 'content')}>
                            Salvar
                          </button>
                        </div>
                      </>
                    )}
                    {!editTopicContent[index] && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
                  </div>
                  <br />
                </div>
              );
            })}
            {/* </div> */}
          </div>
          <br />
          <div className="conclusion">
            <div className="conclusion-title-container">
              <div className="editor-text">
                <VscKebabVertical title="Editar" onClick={() => setEditConclusionTitle(true)} />
              </div>
              {editConclusionTitle && (
                <>
                  <EditorText defaultValue={conclusion.title} onChange={(content) => handleConclusion(content, 'title')} />
                  <div className="btn-save-container">
                    <button className="btn-save" onClick={() => setEditConclusionTitle(false)}>
                      Salvar
                    </button>
                  </div>
                </>
              )}
              {!editConclusionTitle && (
                <p className="conclusion-title">
                  <strong>
                    {conclusion.title && `${convertToRoman(topic.length + 1)}. `}
                    {convertTitle(conclusion.title, 'title')}
                  </strong>
                </p>
              )}
            </div>
            <div className="conclusion-content-container">
              <div className="editor-text">
                <VscKebabVertical
                  title="Editar"
                  onClick={() => setEditConclusionContent(true)}
                  onDoubleClick={() => setEditConclusionContent(false)}
                />
              </div>
              {editConclusionContent && (
                <>
                  <EditorText defaultValue={conclusion.content} onChange={(content) => handleConclusion(content, 'content')} />
                  <div className="btn-save-container">
                    <button className="btn-save" onClick={() => setEditConclusionContent(false)}>
                      Salvar
                    </button>
                  </div>
                </>
              )}
              {!editConclusionContent && (
                <div
                  className="conclusion-content"
                  dangerouslySetInnerHTML={{
                    __html: convertVariables(conclusion.content, processByCpj, variables),
                  }}
                ></div>
              )}
            </div>
          </div>
          <p className="city-date">{`${city}, ${extensiveDate(date)}`}</p>
          <div className="img footer-margin">
            <img src={footerBarcelos} alt="footer Barcelos" className="footer-barcelos" />
          </div>
        </>
      )}
    </div>
  );
}

export default PreviewProcedural;
