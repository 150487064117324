import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderSideBar from '../components/HeaderSideBar';
import InputsProcedural from '../components/InputsProcedural';
import PreviewModal from '../components/PreviewModal';
import PreviewProcedural from '../components/PreviewProcedural';
import ProceduralQuestions from '../components/ProceduralQuestions';
import FormaDocContext from '../context/FormaDocContext';
import {
  convertDateForApi,
  convertDateForFront,
  convertVariables,
  editConclusion,
  editIntroduction,
  editTopics,
  fixFormatting,
  formattedDate,
  handleNextQuestion,
  handlePreviousQuestion,
} from '../helper';

import '../styles/newProcedural.css';
import {
  fetchEditProcedural,
  fetchNewProcedural,
  fetchProceduralByName,
  fetchProceduralDetails,
  fetchSections,
  fetchVariables,
} from '../utils/api';
const debounceFetchProceduralByName = AwesomeDebouncePromise(fetchProceduralByName, 500);

function NewProcedural() {
  const [name, setName] = useState('');
  const [customer, setCustomer] = useState({ label: 'Selecione um cliente', value: '' });
  const [date, setDate] = useState(formattedDate(new Date()));
  const [city, setCity] = useState({ label: 'Belo Horizonte', value: '9' });
  const [legalProcessNumber, setLegalProcessNumber] = useState('');
  const [incidentNumber, setIncidentNumber] = useState('0');
  const [template, setTemplate] = useState('');
  const [documentFormat, setDocumentFormat] = useState({ value: 'pdf', label: 'PDF' });
  const [variables, setVariables] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { answerQuestions, handleAnswerQuestions, questionFilters, completeAnswers, user } = useContext(FormaDocContext);
  const [finishQuestions, setFinishQuestions] = useState(false);
  const [preview, setPreview] = useState(false);
  const [introduction, setIntroduction] = useState({});
  const [conclusion, setConclusion] = useState({});
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [newProcess, setNewProcess] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [processByCpj, setProcessByCpj] = useState(null);
  const [procedural, setProcedural] = useState([]);
  const [divInvalid, setDivInvalid] = useState(false);
  const [sections, setSections] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const handleProcessByCpj = useCallback((processCpj) => {
    setProcessByCpj(processCpj);
  }, []);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));

    fetchVariables().then((res) => {
      const variables = res.data.map((variable) => ({
        id: variable.id,
        name: variable.attributes.name,
        description: variable.attributes.description,
      }));
      setVariables(variables);
    });

    if (location.state && location.state.process) {
      return setIsEdit(true);
    }

    debounceFetchProceduralByName(accessToken, name).then((res) => {
      setProcedural(res.data);
    });
  }, [name, location]);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    const { id } = params;
    if (isEdit) {
      fetchSections(accessToken, id).then((res) => {
        setSections(res.data);
      });

      fetchProceduralDetails(accessToken, id).then((res) => {
        const { attributes, relationships } = res.data;
        const formattedDate = convertDateForFront(attributes.document_date);
        setName(attributes.name);
        setCustomer(relationships.client.data.id);
        setDate(formattedDate);
        setLegalProcessNumber(attributes.process_number);
        setIncidentNumber(attributes.incident_number);
        setDocumentFormat({ label: attributes.document_type.toUpperCase(), value: attributes.document_type });

        res.included.forEach((item) => {
          if (item.type === 'city') {
            setCity({ label: item.attributes.name, value: item.id });
          }
          if (item.type === 'client') {
            setCustomer({ label: item.attributes.name, value: item.id });
          }
          if (item.type === 'template') {
            setTemplate({ label: item.attributes.name, value: item.id });
          }
        });
      });
    }
  }, [isEdit, params]);

  useEffect(() => {
    if (completeAnswers) {
      const answers = Object.values(completeAnswers);
      const topics = answers.filter((answer) => answer.relationships.topic.data);
      const newTopics = topics.map((topic, index) => ({
        order: Number(Object.keys(completeAnswers)[index]),
        ...topic,
      }));
      setTopic(newTopics);
    }
  }, [completeAnswers]);

  useEffect(() => {
    const validateContinueButton = name && customer && date && city && template;
    const THREE_SECONDS = 3000;
    const ONE_SECOND = 1000;
    setTimeout(() => {
      const findName = procedural.find(({ attributes }) => attributes.name === name);
      if (findName && validateContinueButton) {
        setDisabledButton(true);
        setDivInvalid(true);
        setTimeout(() => setDivInvalid(false), THREE_SECONDS);
      } else {
        setDisabledButton(!validateContinueButton);
      }
    }, ONE_SECOND);
  }, [name, customer, date, city, template, procedural]);

  const handlePreview = () => {
    if (questionFilters.length === 0) {
      setPreview(true);
    } else {
      setShowQuestion(true);
    }
  };

  const handleDisabledButtonQuestions = (order) => {
    if (answerQuestions[order]) return false;
    return true;
  };

  const handlePreviousButtonPreview = () => {
    setPreview(false);
    setFinishQuestions(false);
  };

  const returnToList = () => {
    history.push('/minhas-pecas');
  };

  const removeSectionsForUpdate = () =>
    sections.map((section) => {
      let { attributes } = section;
      attributes._destroy = 1;
      return {
        ...attributes,
      };
    });

  const create = (token, date, sections, haveIncidentNumber) => {
    fetchNewProcedural(
      token,
      name,
      incidentNumber,
      legalProcessNumber,
      date,
      'defandant',
      'suitor',
      city.label,
      'process',
      user.id,
      template.value,
      city.value,
      customer.value,
      sections,
      documentFormat.value,
      haveIncidentNumber,
    ).then((res) => {
      setLoading(false);
      setNewProcess(res);
      setModalShow(true);
    });
  };

  const update = (token, date, sections, haveIncidentNumber) => {
    const { id } = params;

    fetchEditProcedural(
      token,
      id,
      name,
      incidentNumber,
      legalProcessNumber,
      date,
      'defandant',
      'suitor',
      city.label,
      'process',
      user.id,
      template.value,
      city.value,
      customer.value,
      sections,
      documentFormat.value,
      haveIncidentNumber,
    ).then((res) => {
      setLoading(false);
      setNewProcess(res);
      setModalShow(true);
    });
  };

  const submitProcedural = () => {
    setLoading(true);
    const access_token = JSON.parse(localStorage.getItem('access_token'));
    const formattedDate = convertDateForApi(date);
    const topics = editTopics(topic, introduction, processByCpj, variables);
    const intro = editIntroduction(introduction, processByCpj, variables);
    const ending = editConclusion(conclusion, intro, topics, processByCpj, variables);
    const haveIncidentNumber = user.office.attributes.hasOwnProperty('process_subidentifier');
    const removeSections = removeSectionsForUpdate();

    let sections = isEdit ? [...removeSections, ...intro, ...topics, ...ending] : [...intro, ...topics, ...ending];

    if (!intro[0].content) {
      sections = isEdit ? [...removeSections, ...topics, ...ending] : [...topics, ...ending];
    }

    if (!ending[0].content) {
      sections = isEdit ? [...removeSections, ...intro, ...topics] : [...intro, ...topics];
    }

    if (!intro[0].content && !ending[0].content) {
      sections = isEdit ? [...removeSections, ...topics] : [...topics];
    }

    sections = sections.map((section) => {
      let { title, content } = section;
      title = convertVariables(title, processByCpj, variables);
      title = fixFormatting(title);
      if (!isEdit) {
        content = convertVariables(content, processByCpj, variables);
        content = fixFormatting(content);
      }
      return { ...section, title, content };
    });

    isEdit
      ? update(access_token, formattedDate, sections, haveIncidentNumber)
      : create(access_token, formattedDate, sections, haveIncidentNumber);
  };

  return (
    <div className="page">
      <div className="all-but-footer">
        <HeaderSideBar />
        <br />
        <div className="container-page">
          <h2 className="title">{isEdit ? '+ Editar peça' : '+ Nova peça'}</h2>
          <div className="card">
            {divInvalid && (
              <div className="invalid-new-procedural">
                <p>
                  Nome da peça deve ser único. Você já possui outra peça com este mesmo nome, para prosseguir escolha outro nome.
                </p>
              </div>
            )}
            {!showQuestion && !preview && (
              <>
                <InputsProcedural
                  name={name}
                  setName={setName}
                  customer={customer}
                  setCustomer={setCustomer}
                  date={date}
                  setDate={setDate}
                  city={city}
                  setCity={setCity}
                  legalProcessNumber={legalProcessNumber}
                  setLegalProcessNumber={setLegalProcessNumber}
                  incidentNumber={incidentNumber}
                  setIncidentNumber={setIncidentNumber}
                  processByCpj={processByCpj}
                  setProcessByCpj={handleProcessByCpj}
                  template={template}
                  setTemplate={setTemplate}
                  setIntroduction={setIntroduction}
                  setConclusion={setConclusion}
                  documentFormat={documentFormat}
                  setDocumentFormat={setDocumentFormat}
                />
                <div className="buttons-new">
                  {
                    <button onClick={returnToList} className={'button-return'}>
                      Voltar
                    </button>
                  }
                  <button type="button" className="btn-proceed" disabled={disabledButton} onClick={() => handlePreview()}>
                    Prosseguir
                  </button>
                </div>
              </>
            )}
            {showQuestion && !finishQuestions && questionFilters.length !== 0 && (
              <>
                <ProceduralQuestions
                  question={questionFilters[currentQuestion]}
                  answerQuestions={questionFilters.length !== 0 && answerQuestions[questionFilters[currentQuestion].order]}
                  handleAnswerQuestions={handleAnswerQuestions}
                />
                <br />
                <div className="buttons-new">
                  <button
                    className="btn-proceed"
                    onClick={() =>
                      handlePreviousQuestion(currentQuestion, setCurrentQuestion, setPreview, setFinishQuestions, setShowQuestion)
                    }
                  >
                    Pergunta anterior
                  </button>
                  <button
                    type="button"
                    className="btn-proceed"
                    disabled={handleDisabledButtonQuestions(
                      questionFilters.length !== 0 && questionFilters[currentQuestion].order,
                    )}
                    onClick={() =>
                      handleNextQuestion(currentQuestion, setCurrentQuestion, questionFilters, setFinishQuestions, setPreview)
                    }
                  >
                    Prosseguir
                  </button>
                </div>
              </>
            )}
            {preview && (
              <>
                <PreviewProcedural
                  name={name}
                  processByCpj={processByCpj}
                  date={date}
                  city={city.label}
                  legalProcessNumber={legalProcessNumber}
                  incidentNumber={incidentNumber}
                  introduction={introduction}
                  setIntroduction={setIntroduction}
                  conclusion={conclusion}
                  setConclusion={setConclusion}
                  topic={topic}
                  variables={variables}
                  setTopic={setTopic}
                />
                <br />
                <div className="buttons-preview">
                  {!introduction && !topic && !conclusion ? (
                    <button className="button-return" onClick={() => handlePreviousButtonPreview()}>
                      Voltar
                    </button>
                  ) : (
                    <>
                      <button className="button-return" disabled={loading} onClick={() => handlePreviousButtonPreview()}>
                        Voltar
                      </button>
                      <button className="btn-proceed" disabled={loading} onClick={() => submitProcedural()}>
                        {loading ? <Spinner animation="border" role="status"></Spinner> : isEdit ? 'Salvar' : 'Cadastrar'}
                      </button>
                    </>
                  )}
                </div>
                {modalShow && (
                  <PreviewModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    process={newProcess}
                    page={isEdit ? 'edit' : 'create'}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewProcedural;
