const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const fetchLogin = async (email, password) => {
  const response = await fetch(BASE_API_URL + 'oauth/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'password',
      email,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  }).then((res) => res.json());
  return response;
};

export const fetchClients = async (token) => {
  const response = await fetch(BASE_API_URL + 'portal/clients?ordering=name:asc', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchResetPassword = async (email) => {
  await fetch(BASE_API_URL + 'portal/lawyers/reset_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
};

export const fetchChangePassword = async (id, current_password, password, password_confirmation) => {
  const token = JSON.parse(localStorage.getItem('access_token'));
  const response = await fetch(BASE_API_URL + `portal/lawyers/${id}/update_password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      current_password,
      password,
      password_confirmation,
    }),
  }).then((res) => res.json());
  return response;
};

export const fetchOfficeDetails = async (token, id) => {
  const response = await fetch(BASE_API_URL + `portal/offices/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchLawyer = async (token) => {
  const response = await fetch(BASE_API_URL + 'portal/lawyers/me', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchCities = async (token) => {
  const response = await fetch(BASE_API_URL + 'portal/cities?ordering=name:asc', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchTemplates = async (token, client, searchBy) => {
  const response = await fetch(
    BASE_API_URL + `portal/templates?by_client=${client}&search_by=${searchBy}&per_page=200&ordering=name:asc`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(),
    },
  ).then((res) => res.json());
  return response;
};

export const fetchTemplatesDetails = async (token, id) => {
  const response = await fetch(BASE_API_URL + `portal/templates/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchNewProcedural = async (
  access_token,
  name,
  incident_number,
  process_number,
  document_date,
  defandant,
  suitor,
  county,
  process,
  lawyer_id,
  template_id,
  city_id,
  client_id,
  sections_attributes,
  document_type,
  haveIncidentNumber,
) => {
  const article = haveIncidentNumber
    ? {
        name,
        incident_number,
        process_number,
        document_date,
        defandant,
        suitor,
        county,
        process,
        lawyer_id,
        template_id,
        city_id,
        client_id,
        sections_attributes,
        document_type,
      }
    : {
        name,
        process_number,
        document_date,
        defandant,
        suitor,
        county,
        process,
        lawyer_id,
        template_id,
        city_id,
        client_id,
        sections_attributes,
        document_type,
      };
  const response = await fetch(`${BASE_API_URL}portal/articles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(article),
  }).then((res) => res.json());
  return response;
};

export const fetchEditProcedural = async (
  access_token,
  id,
  name,
  incident_number,
  process_number,
  document_date,
  defandant,
  suitor,
  county,
  process,
  lawyer_id,
  template_id,
  city_id,
  client_id,
  sections_attributes,
  document_type,
  haveIncidentNumber,
) => {
  const article = haveIncidentNumber
    ? {
        name,
        incident_number,
        process_number,
        document_date,
        defandant,
        suitor,
        county,
        process,
        lawyer_id,
        template_id,
        city_id,
        client_id,
        sections_attributes,
        document_type,
      }
    : {
        name,
        process_number,
        document_date,
        defandant,
        suitor,
        county,
        process,
        lawyer_id,
        template_id,
        city_id,
        client_id,
        sections_attributes,
        document_type,
      };
  const response = await fetch(`${BASE_API_URL}portal/articles/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(article),
  }).then((res) => res.json());
  return response;
};

export const fetchProcedural = async (token, cityId, clientId, name, processNumber, currentPage, initialDate, finalDate) => {
  const response = await fetch(
    BASE_API_URL +
      `portal/articles?ordering=document_date:desc&by_city=${cityId}&by_client=${clientId}&search_by_name=${name}&search_by_process=${processNumber}&per_page=10&page=${currentPage}&until_date=${finalDate}&from_date=${initialDate}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(),
    },
  ).then((res) => res.json());
  return response;
};

export const fetchProceduralDetails = async (token, id) => {
  const response = await fetch(BASE_API_URL + `portal/articles/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchProceduralByName = async (token, name = '', perPage = 1000) => {
  const response = await fetch(
    BASE_API_URL + `portal/articles?search_by_name=${name}&per_page=${perPage}&ordering=document_date:desc`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(),
    },
  ).then((res) => res.json());
  return response;
};

export const fetchProcessInfoByCpj = async (token, processNumber, incidentNumber, isSendIncidentNumber) => {
  incidentNumber = isSendIncidentNumber ? incidentNumber : '';
  const response = await fetch(
    BASE_API_URL +
      `portal/processes_infos?by_process_number=${processNumber}&by_incident_number=${incidentNumber}&ordering=updated_at:desc`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(),
    },
  ).then((res) => res.json());
  return response;
};

export const fetchSections = async (token, articleId) => {
  const response = await fetch(BASE_API_URL + `portal/articles/${articleId}/sections`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchSignatures = async (token, stateId) => {
  const response = await fetch(BASE_API_URL + `portal/lawyer_signatures?by_state=${stateId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};

export const fetchVariables = async () => {
  const token = JSON.parse(localStorage.getItem('access_token'));
  const response = await fetch(BASE_API_URL + `portal/variables?per_page=10000`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(),
  }).then((res) => res.json());
  return response;
};
