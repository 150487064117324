import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { isAuthenticated } from './auth/';
import Provider from './context/Provider';
import ChangePassword from './pages/ChangePassword';
import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import NewProcedural from './pages/NewProcedural';
import ProceduralDocumentsList from './pages/ProceduralDocumentsList';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
  />
);

function Routes() {
  return (
    <BrowserRouter>
      <Provider>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/minhas-pecas" component={ProceduralDocumentsList} />
          <PrivateRoute exact path="/peca/:id/edit" component={NewProcedural} />
          <PrivateRoute exact path="/nova-peca" component={NewProcedural} />
          <Route exact path="/esqueci-senha" component={ForgetPassword} />
          <Route exact path="/alterar-senha" component={ChangePassword} />
        </Switch>
      </Provider>
    </BrowserRouter>
  );
}

export default Routes;
