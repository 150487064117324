import React from 'react';
import { IconContext } from 'react-icons';
import { BsPencil } from 'react-icons/bs';
import { VscFilePdf } from 'react-icons/vsc';
import { convertDateForFront } from '../helper';
import odt from '../images/odt.svg';
import PaginationControlled from './Pagination';

export default function Table({ processList, clients, cities, handleEdit, currentPage, setCurrentPage, totalPages }) {
  const processName = (array, process, string) => {
    if (array) {
      return array.find((arr) => arr.id === process.relationships[string].data.id).attributes.name;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Nome da peça</th>
            <th>Número do processo</th>
            <th>Cliente</th>
            <th>Cidade</th>
            <th>Data</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'middle' }}>
          {processList &&
            processList.map((process) => {
              return (
                <tr key={process.id}>
                  <td data-label="Nome da peça">{process.attributes.name}</td>
                  <td data-label="Número do processo">
                    {!process.attributes.process_number ? <strong>—</strong> : process.attributes.process_number}
                  </td>
                  <td data-label="Cliente">{processName(clients, process, 'client')}</td>
                  <td data-label="Cidade">{processName(cities, process, 'city')}</td>
                  <td data-label="Data">{convertDateForFront(process.attributes.document_date)}</td>
                  <IconContext.Provider value={{ className: 'react-icons' }}>
                    {process.attributes.file ? (
                      <td data-label="Ações" className="edit-down">
                        <div className="btns-table">
                          <button type="button" className="btn-table">
                            <BsPencil className="pencil-edit" onClick={() => handleEdit(process)} />
                          </button>
                          <a href={process.attributes.file} target="_blank" rel="noopener noreferrer">
                            {/* <BsDownload /> */}
                            <button type="button" className="btn-table">
                              {process.attributes.document_type === 'odt' ? (
                                <img src={odt} alt="Logo Horizonti" className="odt-download" />
                              ) : (
                                <VscFilePdf color="red" />
                              )}
                            </button>
                          </a>
                        </div>
                      </td>
                    ) : (
                      <td data-label="Ações" className="edit">
                        <button type="button" className="btn-table">
                          <BsPencil className="pencil-edit" onClick={() => handleEdit(process)} />
                        </button>
                      </td>
                    )}
                  </IconContext.Provider>
                </tr>
              );
            })}
        </tbody>
      </table>
      <PaginationControlled currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
    </div>
  );
}
