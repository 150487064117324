import { createRegex } from './createRegex';

const leaveTheWordInBold = (word) => {
  return `<strong>${word}</strong>`;
};

const replaceTabsForSections = (content) => {
  if (content) {
    content = content.replace(/&lt;tab&gt;/gi, '&emsp;&emsp;&emsp;');
  }

  return content;
};

const removeSpacesFromSections = (content, message) => {
  // const content = top.content.replace(new RegExp('<[^>]*>', 'g'), '')
  // console.log(top.content.replace(new RegExp('<[br>]*>', 'g'), ''));
  // console.log(top.content.replace(new RegExp('<[/o:p]*>', 'g'), ''));
  // const filter = topics.map(topic => {
  //   topic.content = topic.content.replace(new RegExp('<[br>]*>', 'g'), '')
  //   topic.content = topic.content.replace(new RegExp('<[/o:p]*>', 'g'), '')
  //   // console.log(topic.content);
  //   return { ...topic}
  // })
  // return filter;
  if (content && message === 'content') {
    content = content.replace(new RegExp('<[br>]*>', 'g'), '');
    content = content.replace(new RegExp('<[/o:p]*>', 'g'), '');
    content =
      content +
      '<p style="text-align: justify; color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;"><br></p>';
  }

  if (content && ['title', 'conclusion'].includes(message)) {
    content = content.replace(new RegExp('<[br>]*>', 'g'), '');
    content = content.replace(new RegExp('<[/o:p]*>', 'g'), '');
  }
  // content = content.replaceAll('<span style="font-size:11.0pt;font-family:Roboto;mso-bidi-font-family:Arial; color:windowtext">&nbsp;</span>');
  return content;
};

const convertVariables = (convert, processByCpj, variables, type = false) => {
  if (processByCpj && convert) {
    processByCpj.forEach((process) => {
      variables.forEach((variable) => {
        if (variable.name !== 'numero_processo' && process.attributes.variable_id === Number(variable.id)) {
          convert = convert.replace(
            createRegex(`{{ ${variable.name} }}`),
            type ? process.attributes.value : leaveTheWordInBold(process.attributes.value),
          );
          convert = convert.replace(
            createRegex(variable.name),
            type ? process.attributes.value : leaveTheWordInBold(process.attributes.value),
          );
          convert = convert.replace(/{{/g, '');
          convert = convert.replace(/}}/g, '');
        }
      });
    });

    convert = convert.replace(
      createRegex('{{ numero_processo }}'),
      type ? processByCpj[0].attributes.process_number : leaveTheWordInBold(processByCpj[0].attributes.process_number),
    );
    convert = convert.replace(
      createRegex('numero_processo'),
      type ? processByCpj[0].attributes.process_number : leaveTheWordInBold(processByCpj[0].attributes.process_number),
    );
  }

  return convert;
};

const fixFormatting = (convert) => {
  if (convert) {
    const strong = '<strong>';
    const strongCustomStyle = '<strong $1>';
    const strongStyle = '<strong style="background-color: transparent; font-size: 1rem; text-indent: 70.9pt;">';
    const closeStrong = '</strong>';
    convert = convert.replace(/<b>/gi, strong);
    convert = convert.replace(/<b (.+)>/gi, strongCustomStyle);
    convert = convert.replace(/<b style="background-color: transparent; font-size: 1rem; text-indent: 70.9pt;">/gi, strongStyle);
    convert = convert.replace(/<\/b>/gi, closeStrong);

    convert = convert.replace(/{{&nbsp;}}/gi, '');
    convert = convert.replace(/{{&nbsp;/gi, '');
    convert = convert.replace(/&nbsp;}}/gi, '');
  }

  return convert;
};

export { convertVariables, fixFormatting, leaveTheWordInBold, replaceTabsForSections, removeSpacesFromSections };
