import { convertToRoman } from './convertToRoman';
import { convertVariables, fixFormatting, removeSpacesFromSections, replaceTabsForSections } from './replaceVariables';

const editIntroduction = (introduction, processByCpj, processesInfo, oabs, variables) => {
  let intro = null;
  if (introduction.title) {
    intro = {
      ...introduction,
      title: introduction.title,
      content: introduction.content,
      order: 1,
    };
    intro = [intro].map((introd) => {
      let { content } = introd;
      content = convertVariables(content, processByCpj, processesInfo, oabs, variables);
      content = fixFormatting(content);
      content = removeSpacesFromSections(content, 'content');
      content = replaceTabsForSections(content);
      return { ...introd, content };
    });
  } else {
    intro = {
      content: introduction.content,
      order: 1,
    };
    intro = [intro].map((introd) => {
      let { content } = introd;
      content = convertVariables(content, processByCpj, processesInfo, oabs, variables);
      content = fixFormatting(content);
      content = removeSpacesFromSections(content, 'content');
      content = replaceTabsForSections(content);
      return { ...introd, content };
    });
  }
  return intro;
};

const editTopics = (topic, introduction, processByCpj, variables) => {
  return topic.map(({ order, relationships }, index) => {
    let { title } = relationships.topic.data.attributes;
    title = convertVariables(title, processByCpj, variables);
    title = fixFormatting(title);
    title = `${convertToRoman(index + 1)}. ` + title;

    let { content } = relationships.topic.data.attributes;
    content = convertVariables(content, processByCpj, variables);
    content = fixFormatting(content);

    if (introduction.content) {
      order = order + 1;
    }

    content = removeSpacesFromSections(content, 'content');
    content = replaceTabsForSections(content);

    return { order, title, content };
  });
};

const editConclusion = (conclusion, intro, topics, processByCpj, variables) => {
  let ending = null;
  if (conclusion.title) {
    ending = {
      ...conclusion,
      title: conclusion.title,
      content: conclusion.content,
      order: topics.length !== 0 ? [...topics].pop().order + 1 : intro.order + 1,
    };
    ending = [ending].map((end) => {
      let { title, content } = end;
      title = `${convertToRoman(topics.length + 1)}. ` + title;
      content = convertVariables(content, processByCpj, variables);
      content = fixFormatting(content);
      content = removeSpacesFromSections(content, 'conclusion');
      content = replaceTabsForSections(content);
      return { ...end, title, content };
    });
  } else {
    ending = {
      content: conclusion.content,
      order: topics.length !== 0 ? [...topics].pop().order + 1 : intro.order + 1,
    };
    ending = [ending].map((end) => {
      let { content } = end;
      content = convertVariables(content, processByCpj, variables);
      content = fixFormatting(content);
      content = removeSpacesFromSections(content, 'conclusion');
      content = replaceTabsForSections(content);
      return { ...end, content };
    });
  }
  return ending;
};

export { editIntroduction, editTopics, editConclusion };
