import React from 'react';
import '../styles/footer.css';
import logoHorizonti from '../images/horizonti_horizontal_branco.png';

function Footer() {
  return (
    <div className="footer">
      <a target="_blank" href="https://horizonti.digital/" rel="noreferrer">
        <img src={logoHorizonti} alt="Logo Horizonti" className="logo-horizonti" />
      </a>
    </div>
  )
}

export default Footer;
