export const isAuthenticated = () => {
  const access_token = JSON.parse(localStorage.getItem('access_token'));
  // const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
  const expires = JSON.parse(localStorage.getItem('expires'));
  if (!access_token) {
    localStorage.clear();
    return false;
  }

  const actualTimestamp = new Date().getTime();

  if (actualTimestamp <= expires) {
    return true;
  }

  localStorage.clear();
  return false;
};
