import React, { useContext, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router';
import FormaDocContext from '../context/FormaDocContext';
import logo from '../images/logo.png';
import '../styles/login.css';
import { fetchLawyer, fetchLogin } from '../utils/api';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [divInvalid, setDivInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [componentMounted, setComponentMounted] = useState(true);
  const { handleUser } = useContext(FormaDocContext);

  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    if (componentMounted) {
      fetchLogin(email, password).then((res) => {
        localStorage.setItem('access_token', JSON.stringify(res.access_token));
        localStorage.setItem('refresh_token', JSON.stringify(res.refresh_token));
        const expires = (res.expires_in + res.created_at) * 1000;
        localStorage.setItem('expires', JSON.stringify(expires));

        if (res.access_token) {
          fetchLawyer(res.access_token).then((response) => {
            const userInfo = response.included.find((item) => item.type === 'user');
            const office = response.included.find((item) => item.type === 'office');
            const user = {
              id: response.data.id,
              name: response.data.attributes.name,
              office,
              email: userInfo.attributes.email,
              signInCount: userInfo.attributes.sign_in_count,
            };
            localStorage.setItem('user', JSON.stringify(user));
            handleUser(user);
            if (response.included[0].attributes.sign_in_count <= 1) {
              history.push({
                pathname: '/alterar-senha',
                state: {
                  firstAccess: { password },
                },
              });
            } else {
              history.push('/minhas-pecas');
            }
          });
        } else {
          setDivInvalid(true);
          setTimeout(() => setDivInvalid(false), 3000);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    return () => {
      setComponentMounted(false);
    };
  }, []);

  const forgetPassword = () => {
    history.push('/esqueci-senha');
  };

  return (
    <div className="page-login">
      {divInvalid && (
        <div className="invalid">
          <p>E-mail ou senha inválidos</p>
        </div>
      )}
      <div className="login">
        <img src={logo} height="50" alt="logo" className="logo-login" />
        <br />
        <br />
        <form onSubmit={handleClick}>
          <div className="div-input-login">
            <label htmlFor="email" className="label-login">
              E-mail
            </label>
            <input
              className="input-login"
              required
              type="email"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="div-input-login">
            <label htmlFor="password" className="label-login">
              Senha
            </label>
            <input
              required
              className="input-login"
              type="password"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="buttons-login">
            <button className="forgot-password" type="button" onClick={() => forgetPassword()}>
              Esqueceu sua senha?
            </button>
            <button className="btn-login" type="submit">
              {loading ? <Spinner animation="border" role="status" /> : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
