import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import FormaDocContext from './FormaDocContext';

function Provider({ children }) {
  const [answerQuestions, setAnswerQuestions] = useState({});
  const [completeAnswers, setCompleteAnswers] = useState({});
  const [questionFilters, setQuestionFilters] = useState([]);

  const [user, setUser] = useState({});

  const handleQuestionFilters = useCallback((newQuestionFilter) => setQuestionFilters(newQuestionFilter), []);

  function handleAnswerQuestions(order, answer) {
    setAnswerQuestions({ ...answerQuestions, [order]: answer });
  }

  const handleCompleteAnswer = useCallback(
    (order, answer) => setCompleteAnswers((prevState) => ({ ...prevState, [order]: answer })),
    [],
  );

  const handleUser = useCallback((newUser) => setUser(newUser), []);

  const context = {
    answerQuestions,
    setAnswerQuestions,
    handleAnswerQuestions,
    questionFilters,
    handleQuestionFilters,
    completeAnswers,
    setCompleteAnswers,
    handleCompleteAnswer,
    user,
    handleUser,
  };

  return <FormaDocContext.Provider value={context}>{children}</FormaDocContext.Provider>;
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
