import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router';
import Footer from '../components/Footer';
import HeaderSideBar from '../components/HeaderSideBar';
import PreviewModal from '../components/PreviewModal';
import FormaDocContext from '../context/FormaDocContext';
import { changePasswordValidation } from '../helper';
import '../styles/changePassword.css';
import { fetchChangePassword } from '../utils/api';

function ChangePassword() {
  const { user, handleUser } = useContext(FormaDocContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [changePassword, setChangePassword] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState({
    password: false,
    passwordConfirmation: false,
    passwords: false,
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.firstAccess) {
      const { firstAccess } = location.state;
      setCurrentPassword(firstAccess.password);
    }
  }, [location]);

  const handleClick = (e) => {
    e.preventDefault();
    if (
      changePasswordValidation(password, passwordConfirmation, setValidated)
    ) {
      setLoading(true);
      fetchChangePassword(
        user.id,
        currentPassword,
        password,
        passwordConfirmation
      ).then((response) => {
        response.status
          ? setChangePassword({
              status: response.status,
              error: response.message,
            })
          : setChangePassword(response);
        const userLocal = JSON.parse(localStorage.getItem('user'));
        const newUser = {
          ...userLocal,
          signInCount: [0, 1].includes(userLocal.signInCount)
            ? userLocal.signInCount + 2
            : userLocal.signInCount,
        };
        localStorage.setItem('user', JSON.stringify(newUser));
        handleUser(newUser);
        setModalShow(true);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div className="page">
        <div className="all-but-footer">
          <HeaderSideBar />
          <br />
          <div className="container-page">
            <div className="card">
              <p className="paragraph-forgot">Alterar senha</p>
              <form onSubmit={handleClick} noValidate>
                <div className="div-input-login">
                  <label htmlFor="current-password" className="label-login">
                    Senha atual
                  </label>
                  <input
                    required
                    className="input-login"
                    type="password"
                    id="current-password"
                    value={currentPassword}
                    onChange={(event) => setCurrentPassword(event.target.value)}
                  />
                  <label htmlFor="password" className="label-login">
                    Nova senha
                  </label>
                  <input
                    required
                    className="input-login"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  {validated.password && (
                    <p className="password-error">
                      Insira uma senha com no mínino 8 caracteres
                    </p>
                  )}
                  <label
                    htmlFor="password-confirmation"
                    className="label-login"
                  >
                    Repita a nova senha
                  </label>
                  <input
                    required
                    className="input-login"
                    type="password"
                    id="password-confirmation"
                    value={passwordConfirmation}
                    onChange={(event) =>
                      setPasswordConfirmation(event.target.value)
                    }
                  />
                </div>
                {validated.passwordConfirmation && (
                  <p className="password-error">
                    Insira uma senha com no mínino 8 caracteres
                  </p>
                )}
                {!validated.password &&
                  !validated.passwordConfirmation &&
                  validated.passwords && (
                    <p className="password-error">Senhas diferentes</p>
                  )}
                <button className="btn-forget" type="submit">
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    'Alterar senha'
                  )}
                </button>
              </form>
              {modalShow && (
                <PreviewModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  process={changePassword}
                  page="changePassword"
                />
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ChangePassword;
