import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { HiOutlineDocumentText } from 'react-icons/hi';
import ReactInputDateMask from 'react-input-date-mask';
import { useHistory } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderSideBar from '../components/HeaderSideBar';
import { fetchCities, fetchClients, fetchProcedural } from '../utils/api';

import Table from '../components/Table';
import { convertDateForApi, verifyDate } from '../helper';
import '../styles/proceduralDocumentsList.css';

const debounceFetchProcedural = AwesomeDebouncePromise(fetchProcedural, 200);

function ProceduralDocumentsList() {
  const [clients, setClients] = useState();
  const [cities, setCities] = useState();
  const [customerId, setCustomerId] = useState('');
  const [cityId, setCityId] = useState('');
  const [proceduralName, setProceduralName] = useState('');
  const [proceduralNumber, setProceduralNumber] = useState('');
  const [processList, setProcessList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [initalDate, setInitalDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [outdated, setOutdated] = useState(true);

  const history = useHistory();

  const newProcedural = () => {
    history.push('/nova-peca');
  };

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    fetchClients(accessToken).then((res) => {
      setClients(res.data);
    });
    fetchCities(accessToken).then((res) => {
      setCities(res.data);
    });
  }, []);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access_token'));
    const convertDateForSearch = (date) => {
      if (!date || verifyDate(date)) return '';
      return convertDateForApi(date);
    };

    const fromDate = convertDateForSearch(initalDate);
    const untilDate = convertDateForSearch(finalDate);

    if (outdated && !loading) {
      setOutdated(false);
      setLoading(true);
      debounceFetchProcedural(
        accessToken,
        cityId,
        customerId,
        proceduralName,
        proceduralNumber,
        currentPage,
        fromDate,
        untilDate,
      ).then((res) => {
        setTotalPages(res.meta.total_pages);
        setProcessList(res.data);
        setLoading(false);
      });
    }
  }, [cityId, customerId, proceduralName, proceduralNumber, currentPage, initalDate, finalDate, outdated, loading]);

  useEffect(() => {
    setOutdated(true);
  }, [cityId, customerId, proceduralName, proceduralNumber, currentPage, initalDate, finalDate]);

  const handleEdit = (process) => {
    history.push({
      pathname: `/peca/${process.id}/edit`,
      state: {
        process,
      },
    });
  };

  return (
    <div className="page">
      <div className="all-but-footer">
        <HeaderSideBar />
        <br />
        <div className="container-page">
          <div className="title">
            <HiOutlineDocumentText size="28" />
            <h2>Peças</h2>
          </div>
          <div className="card">
            <div>
              <button type="button" className="new-procedural" onClick={newProcedural}>
                + Nova peça
              </button>
            </div>
            <br />
            <div className="div-search-input">
              <input
                type="text"
                placeholder="Buscar por nome da peça"
                className="search-input"
                value={proceduralName}
                onChange={({ target }) => setProceduralName(target.value)}
              />
              <select
                className="search-input select-clients"
                value={customerId}
                onChange={(event) => setCustomerId(event.target.value)}
              >
                <option hidden>Buscar por cliente</option>
                {clients &&
                  clients.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.attributes.name}
                    </option>
                  ))}
              </select>
              <select className="search-input select-clients" value={cityId} onChange={(event) => setCityId(event.target.value)}>
                <option hidden>Buscar por cidade</option>
                {cities &&
                  cities.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.attributes.name}
                    </option>
                  ))}
              </select>
              <input
                type="number"
                size="24"
                placeholder="Buscar por número do processo"
                min="0"
                className="search-input"
                value={proceduralNumber}
                onChange={({ target }) => setProceduralNumber(target.value)}
              />
              <div className="search-dates">
                <div className="search-date">
                  <label htmlFor="initialDate">Data inicial</label>
                  <ReactInputDateMask
                    mask="dd/mm/yyyy"
                    className="search-input-date"
                    value={initalDate}
                    onChange={(value) => setInitalDate(value)}
                    showMaskOnFocus={true}
                    id="initialDate"
                  />
                </div>
                <div className="search-date">
                  <label htmlFor="finalDate">Data Final</label>
                  <ReactInputDateMask
                    mask="dd/mm/yyyy"
                    className="search-input-date"
                    value={finalDate}
                    onChange={(value) => setFinalDate(value)}
                    showMaskOnFocus={true}
                    id="finalDate"
                  />
                </div>
              </div>
            </div>
            <br />
            {loading ? (
              <div className="spinner">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <Table
                processList={processList}
                clients={clients}
                cities={cities}
                handleEdit={handleEdit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProceduralDocumentsList;
