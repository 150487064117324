import React, { useContext } from "react";
import FormaDocContext from "../context/FormaDocContext";

import '../styles/proceduralQuestions.css';

function NewProceduralQuestions({ question, answerQuestions, handleAnswerQuestions }) {
  const { title, answers } = question;
  const { handleCompleteAnswer } = useContext(FormaDocContext);
  return (
    <div className="questions">
      <div className="answers-radio">
        
      <p>{title}</p>
      {answers.map((answer, key) => (
        <label htmlFor={`option-${key}`} key={key + 1}>
          <input
            type="radio"
            id={`option-${key}`}
            name="answerRadio"
            value={answer.attributes.title}
            checked={answer.attributes.title === answerQuestions}
            onChange={({ target }) =>
              handleAnswerQuestions(question.order, target.value)
            }
            onClick={() => handleCompleteAnswer(question.order, answer)}
          />
          {answer.attributes.title}
        </label>
      ))}
      </div>
    </div>
  );
}

// modelo do array de question
// answers: [
// attributes: {
// follow_mode: "automatic"
// next_question_id: null
// title: "Expedita officiis quisquam iusto et laudantium provident dolor cum."
// }
// id: "5"
// relationships: {
// topic: {
// data: null

// }
// type: "option"
// ]
// order: 177
// title: "Quaerat veritatis consequatur incidunt recusandae quae iusto quis. Animi nostrum similique quisquam delectus quidem. Expedita id consequuntur debitis

export default NewProceduralQuestions;
